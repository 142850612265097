import { EventState } from '@/enums/EventState';
import { ThinEvent } from '@/resources/ThinEvent';

export const getEventHref = (event: ThinEvent): string => {
  if (event.state === EventState.PostEvent) {
    return `/events/${event.id}/library`;
  }

  return `/events/${event.id}/reception`;
};

export const getEventHrefFull = (event: ThinEvent): string => {
  if (event.state === EventState.PostEvent) {
    return `${process.env.NEXT_PUBLIC_BASE_URL}/events/${event.id}/library`;
  }

  return `${process.env.NEXT_PUBLIC_BASE_URL}/events/${event.id}/reception`;
};
