import { chakra, forwardRef, ThemingProps, omitThemingProps, useStyleConfig, HTMLChakraProps } from '@chakra-ui/react';
import { createContext, MaybeRenderProp } from '@chakra-ui/react-utils';
import { cx, runIfFn, __DEV__ } from '@chakra-ui/utils';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import { IUseSearchOptions, useSearch } from '@/hooks/use-search/useSearch';

export interface IRenderProps {
  query?: string;
}

type SearchContext = ReturnType<typeof useSearch>;

export const [SearchProvider, useSearchContext] = createContext<SearchContext>({
  name: 'SearchContext',
  errorMessage:
    'useSearchContext: context is undefined. Seems you forgot to wrap the search components in `<SearchProvider />`',
});

export interface ISearchProps extends Omit<HTMLChakraProps<'div'>, 'children'>, ThemingProps, IUseSearchOptions {}

export const Search = forwardRef<ISearchProps, 'div'>(function Search(props, ref) {
  const styles = useStyleConfig('Search', props);

  const { children, queryParamName, method, shallow, debounceWait, minInputLength, ...rest } = omitThemingProps(props);

  const useSearchOptions = omitBy({ queryParamName, method, shallow, debounceWait, minInputLength }, isUndefined);

  const context = useSearch(useSearchOptions);

  const chakraClassName = cx('chakra-search', props.className);

  const componentStyles = {
    // place to add common styles
    ...styles,
  };

  return (
    <SearchProvider value={context}>
      <chakra.div ref={ref} className={chakraClassName} __css={componentStyles} {...rest}>
        {children}
      </chakra.div>
    </SearchProvider>
  );
});

if (__DEV__) {
  Search.displayName = 'Search';
}

export type ISearchInputProps = HTMLChakraProps<'input'>;

export const SearchInput = forwardRef<ISearchInputProps, 'div'>(function SearchEmpty(props, ref) {
  const { getSearchInputProps } = useSearchContext();

  return <chakra.input ref={ref} {...props} {...getSearchInputProps()} />;
});

if (__DEV__) {
  SearchInput.displayName = 'SearchInput';
}

export interface ISearchEmptyProps extends Omit<HTMLChakraProps<'div'>, 'children'> {
  children?: MaybeRenderProp<IRenderProps>;
}

export const SearchEmpty = forwardRef<ISearchEmptyProps, 'div'>(function SearchEmpty(props, ref) {
  const { query } = useSearchContext();

  if (query) {
    return null;
  }

  const children = runIfFn(props.children, {
    query,
  });

  return (
    <chakra.div ref={ref} {...props}>
      {children}
    </chakra.div>
  );
});

if (__DEV__) {
  SearchEmpty.displayName = 'SearchEmpty';
}

export interface ISearchResultsProps extends Omit<HTMLChakraProps<'div'>, 'children'> {
  children?: MaybeRenderProp<IRenderProps>;
}

export const SearchResults = forwardRef<ISearchResultsProps, 'div'>(function SearchResults(props, ref) {
  const { query } = useSearchContext();

  if (!query) {
    return null;
  }

  const childrenIfFn = runIfFn(props.children, {
    query,
  });

  return (
    <chakra.div ref={ref} {...props}>
      {childrenIfFn}
    </chakra.div>
  );
});

if (__DEV__) {
  SearchResults.displayName = 'SearchResults';
}

export function useSearchState() {
  const context = useSearchContext();

  return context;
}
