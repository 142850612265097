import { useCallbackRef } from '@chakra-ui/react';
import { useResource } from 'datx-jsonapi-react';
import { useRouter } from 'next/router';

import { queryLiblynxAuthorizationGrants } from '@/queries/liblynx-authorization-grants';
import { ILibLynxProps, LibLynxEventNames } from '@/types/LibLynx';

export const useLibLynx = () => {
  const { data } = useResource(queryLiblynxAuthorizationGrants('auth_grant'));
  const router = useRouter();

  const send = useCallbackRef((eventName: LibLynxEventNames, eventProps: Partial<ILibLynxProps> = {}) => {
    if (!data?.liblynxAccountId) return;
    const eventQueueProps = {
      url: process.env.NEXT_PUBLIC_BASE_URL + router.asPath.substring(1),
      ...eventProps,
    };

    window._ll.push([eventName, eventQueueProps]);
  });

  return { send };
};
