import { useMemo } from 'react';

import { useClientWindowScroll } from './useClientWindowScroll';

export const useScrollBackTo = (topOffset = 0, scrollingOffset = 0) => {
  const clientWindowScroll = useClientWindowScroll();

  const onBackTo = () => {
    window.scrollTo({
      top: topOffset - scrollingOffset,
      left: 0,
      behavior: 'smooth',
    });
  };

  const shouldDisplayBackTo = useMemo(() => {
    return clientWindowScroll.y > topOffset - scrollingOffset;
  }, [clientWindowScroll.y, scrollingOffset, topOffset]);

  return {
    onBackTo,
    shouldDisplayBackTo,
  };
};
