import { Card, Flex, ListItem, Skeleton, UnorderedList } from '@chakra-ui/react';
import { useResource } from 'datx-jsonapi-react';
import { FC } from 'react';

import { Markdown } from '@/components/Markdown';
import { DescriptionCard } from '@/components/ui/shared/cards/DescriptionCard/DescriptionCard';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { queryThinConferenceBySlug } from '@/queries/thin-conferences';

interface IUrlQueryStringParams {
  query?: string;
  slug?: string;
}

export const PublisherDescriptionFallback: FC = () => {
  return (
    <Flex flexDir="column" bg="white">
      <Skeleton mb={{ base: 4, sm: 6, lg: 12 }} w={40} h={9} />
      <Flex flexDir={{ base: 'column', lg: 'row' }}>
        <Flex pr={6} mr={4} mb={8} flexDir="column" w="100%">
          <Skeleton w={80} h={9} />
          <Skeleton w="100" h={9} my={3} />
          <Skeleton w="100" h={9} />
          <Skeleton w="100" h={9} my={3} />
          <Skeleton w="100" h={9} />
        </Flex>
        <Flex pl={6} flexDir="column" w={96}>
          <Skeleton w={40} h={9} />
          <UnorderedList pl={2} pb={6}>
            <ListItem my={3}>
              <Skeleton w="100%" h="24px" />
            </ListItem>
            <ListItem>
              <Skeleton w="100%" h="24px" />
            </ListItem>
            <ListItem my={3}>
              <Skeleton w="100%" h="24px" />
            </ListItem>
            <ListItem>
              <Skeleton w="100%" h="24px" />
            </ListItem>
          </UnorderedList>
          <Skeleton w={20} h={9} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const PublisherDescription: FC = () => {
  const { slug } = useQueryParams<IUrlQueryStringParams>({
    slug: StringParam,
    query: StringParam,
  });

  const { data } = useResource(queryThinConferenceBySlug(slug));

  if (!data) {
    return <PublisherDescriptionFallback />;
  }

  return (
    <Flex flexDir={{ base: 'column', lg: 'row' }} mb={8}>
      {data.description && (
        <DescriptionCard
          description={data.description}
          mr={4}
          height={'fit-content'}
          border="1px solid"
          borderColor="gray.100"
          borderRadius={8}
          w="100%"
          mb={4}
        />
      )}
      {data.additionalInformation && (
        <Card
          variant="outline"
          minW={{ base: '100%', lg: '389px' }}
          borderRadius={8}
          borderColor="gray.100"
          height="fit-content"
          p={4}
          flex={1}
        >
          <Markdown>{data.additionalInformation}</Markdown>
        </Card>
      )}
    </Flex>
  );
};
