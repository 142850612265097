import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { ThinConferenceSort } from '@/enums/ThinConferenceSort';
import {
  QueryResource,
  QueryResourceRelationship,
  QueryResources,
  QueryResourcesInfinite,
} from '@/lib/datx-jsonapi-react';
import { ThinConference } from '@/resources/ThinConference';
import { ThinConfiguration } from '@/resources/ThinConfiguration';

export const queryThinConference = (id: string): QueryResource<ThinConference> => {
  return id ? [ThinConference, id] : null;
};

export const queryThinConferenceBySlug = (slug: string): QueryResource<ThinConference> => {
  if (!slug) return null;
  return [
    ThinConference,
    slug,
    {
      params: [
        {
          key: 'find_by',
          value: 'slug',
        },
      ],
    },
  ];
};

export const queryThinConferenceConfigurationRelationship = (
  id: string
): QueryResourceRelationship<ThinConfiguration> => (id ? [ThinConference.type, id, 'configuration'] : null);

export const queryThinConferencesInfinite = (
  filters: Record<string, string | Array<string>>,
  sort = ThinConferenceSort.ExtendedName,
  pageSize = 16
): QueryResourcesInfinite<ThinConference> => {
  return (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.links?.next) {
      return null;
    }

    const pageNumber = pageIndex + 1;

    return [
      ThinConference,
      {
        filter: omitBy(filters, isNil),
        sort,
        params: [
          {
            key: 'page[size]',
            value: pageSize.toString(),
          },
          {
            key: 'page[number]',
            value: pageNumber.toString(),
          },
        ],
      },
    ];
  };
};

export const queryThinConferencesSorted = (pageSize = 20): QueryResources<ThinConference> => {
  return [
    ThinConference,
    {
      sort: 'extended_name',
      filter: {
        listed: 'true',
      },
      params: [
        {
          key: 'page[size]',
          value: pageSize.toString(),
        },
        {
          key: 'page[number]',
          value: '1',
        },
      ],
    },
  ];
};
