import { HTMLChakraProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from '@/components/ui/shared/inputs/SearchInput/SearchInput';
import { useSearch } from '@/hooks/use-search/useSearch';

export const PublisherSearchInput: FC<HTMLChakraProps<'div'>> = (props) => {
  const { t } = useTranslation();
  const { getSearchInputProps } = useSearch();

  return (
    <SearchInput
      {...props}
      bg="white"
      borderRadius="full"
      {...getSearchInputProps()}
      placeholder={t('publisher.search.placeholder')}
    />
  );
};
