import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Link,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';
import { useResource } from 'datx-jsonapi-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmailIcon from '@/assets/icons/ic-share-email-circle.svg';
import FbIcon from '@/assets/icons/ic-share-facebook-circle.svg';
import LdIcon from '@/assets/icons/ic-share-linkedin-circle.svg';
import TwIcon from '@/assets/icons/ic-share-twitter-circle.svg';
import PublisherLogoFallback from '@/assets/images/publisher/publisher-logo-fallback.png';

import { Media, MediaBody, MediaImage } from '@/components/ui/core/Media/Media';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { queryThinConferenceBySlug } from '@/queries/thin-conferences';

interface IUrlQueryStringParams {
  query?: string;
  slug?: string;
}

export const PublisherHeaderFallback: FC = () => {
  return (
    <Flex flexDir={{ base: 'column', lg: 'row' }} pt={14} pb={9}>
      <Box>
        <Skeleton w="264px" h="264px" />
      </Box>
      <Flex flex={{ base: 'none', lg: '1' }} flexDir="column" ml={{ base: 0, lg: 20 }} mt={{ base: 10, lg: 0 }}>
        <Skeleton h={9} w={96} mb={5} />
        <Skeleton h={9} w={96} mb={5} />
        <HStack mb={4}>
          <Box pr={5}>
            <Skeleton w={20} h={9} />
          </Box>
          <Box pr={5}>
            <Skeleton w={20} h={9} />
          </Box>
          <Box pr={5}>
            <Skeleton w={20} h={9} />
          </Box>
        </HStack>
        <HStack mb={5}>
          <Box pr={5}>
            <SkeletonCircle size="10" />
          </Box>
          <Box pr={5}>
            <SkeletonCircle size="10" />
          </Box>
          <Box pr={5}>
            <SkeletonCircle size="10" />
          </Box>
          <Box pr={5}>
            <SkeletonCircle size="10" />
          </Box>
        </HStack>
        <Skeleton h={9} w={96} />
      </Flex>
    </Flex>
  );
};

export const PublisherHeader: FC = () => {
  const { t } = useTranslation();
  const { slug } = useQueryParams<IUrlQueryStringParams>({
    slug: StringParam,
    query: StringParam,
  });
  const { data } = useResource(queryThinConferenceBySlug(slug));

  if (!data) {
    return <PublisherHeaderFallback />;
  }

  return (
    <Media pt="14">
      <MediaImage
        src={data.largeLogoUrl || PublisherLogoFallback.src}
        alt={data.abbreviation}
        alignSelf="center"
        boxShadow="card2xl"
      />
      <MediaBody justifyContent="center">
        <Flex flexDir="column" textAlign={{ base: 'center', lg: 'initial' }}>
          <Heading
            as="h2"
            mb={4}
            fontWeight="bold"
            lineHeight={{ base: '44px', lg: '60px' }}
            fontSize={{ base: '36px', lg: '48px' }}
          >
            {data.abbreviation}
          </Heading>
          <Text mb={6} color="gray.600" textTransform="uppercase" fontSize={{ base: '14px', lg: '16px' }}>
            {data.name}
          </Text>
          <HStack
            mb={6}
            justify={{ base: 'center', lg: 'flex-start' }}
            maxW="350px"
            spacing={{ base: 4, sm: 8 }}
            alignSelf={{ base: 'center', lg: 'flex-start' }}
          >
            <Stat variant="inline">
              <StatNumber mr={2} fontSize="2xl" fontWeight="bold">
                {data?.visibleLibraryEventsCount}
              </StatNumber>
              <StatLabel fontSize="md">{t('common.event_plural')}</StatLabel>
            </Stat>
            <Stat variant="inline">
              <StatNumber mr={2} fontSize="2xl" fontWeight="bold">
                {data?.authorsSpeakersCount}
              </StatNumber>
              <StatLabel fontSize="md">{t('common.author_plural')}</StatLabel>
            </Stat>
            <Stat variant="inline">
              <StatNumber mr={2} fontSize="2xl" fontWeight="bold">
                {data.lecturesCount}
              </StatNumber>
              <StatLabel fontSize="md">{t('common.lecture_plural')}</StatLabel>
            </Stat>
          </HStack>
          <HStack mb={6} justify={{ base: 'center', lg: 'flex-start' }} spacing="22px">
            {data?.facebook && (
              <Link href={data.facebook} isExternal>
                <Icon boxSize="30px" as={FbIcon} />
              </Link>
            )}
            {data?.twitter && (
              <Link href={data.twitter} isExternal>
                <Icon boxSize="30px" as={TwIcon} />
              </Link>
            )}
            {data?.linkedin && (
              <Link href={data.linkedin} isExternal>
                <Icon boxSize="30px" as={LdIcon} />
              </Link>
            )}
            {data?.email && (
              <Link href={`mailto:${data.email}`}>
                <Icon boxSize="30px" as={EmailIcon} />
              </Link>
            )}
          </HStack>
          {data?.site && (
            <Link href={data.site} isExternal>
              <Text fontWeight="semibold">{data.site}</Text>
            </Link>
          )}
        </Flex>
      </MediaBody>
    </Media>
  );
};
