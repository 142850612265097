import { Box, Flex, Grid, Heading, Text, Link, HTMLChakraProps } from '@chakra-ui/react';
import { Response } from 'datx-jsonapi';
import { useResource, useResourcesInfinite } from 'datx-jsonapi-react';
import NextLink from 'next/link';
import { FC, useMemo, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { BackToTop } from '@/components/ui/core/BackToTop/BackToTop';
import { DEFAULT_PAGE_SIZE } from '@/components/ui/features/publisher/PublisherEventsGrid/PublisherEventsGrid.constants';
import { EventCardFallback, UpcomingEventCard } from '@/components/ui/shared/event/EventCard/EventCard';
import { NoResults } from '@/components/ui/shared/messages/NoResults/NoResults';
import { PublisherCategory } from '@/enums/PublisherCategory';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { queryThinConferenceBySlug } from '@/queries/thin-conferences';
import { queryEventsByQueryInfinite } from '@/queries/thin-events';
import { ThinEvent } from '@/resources/ThinEvent';
import { getEventHrefFull } from '@/utils/getEventHref';
import { extractSWRInfiniteData } from '@/utils/swr/extractSWRInfiniteData';

interface IUrlQueryStringParams {
  slug?: string;
  query?: string;
  tabId?: string;
}

export const PublisherEventsGrid: FC<HTMLChakraProps<'div'>> = ({ ...rest }) => {
  const { t } = useTranslation();
  const { query, tabId = PublisherCategory.Description, slug } = useQueryParams<IUrlQueryStringParams>({
    slug: StringParam,
    tabId: StringParam,
    query: StringParam,
  });
  const eventsListRef = useRef();
  const { data: publisherData } = useResource(queryThinConferenceBySlug(slug));
  const { data, error, size, setSize } = useResourcesInfinite(
    queryEventsByQueryInfinite({
      filters: {
        query: query ? query : null,
        library: 'true',
        visible: 'true',
        conference_id: publisherData?.id,
      },
      pageSize: DEFAULT_PAGE_SIZE,
    }),
    { revalidateAll: true }
  );

  const { items, isReachingEnd, isLoadingMore, isEmpty, meta } = useMemo(
    () => extractSWRInfiniteData<ThinEvent>(data as Array<Response<ThinEvent>>, error, size),
    [data, error, size]
  );

  if (size > meta?.totalPages) {
    setSize(meta.totalPages);
  }

  return (
    <Box {...rest}>
      <Flex mb={12} justifyContent={'space-between'} alignItems={'center'}>
        <Heading as="h2" fontSize={{ base: '2xl', lg: '3xl' }} textTransform="capitalize">
          {t('common.event_plural')}
        </Heading>
        {!isLoadingMore && (query || tabId !== 'description') && (
          <Text textStyle="grotesk">{t('common.resultWithCount_plural', { resultCount: meta?.totalCount || 0 })}</Text>
        )}
      </Flex>
      {isEmpty && (
        <NoResults
          title={query ? t('common.noResultsForQuery', { query }) : t('common.noResults')}
          subtitleSlot={
            <Text fontSize="md" lineHeight="22px" mt={3}>
              <Trans
                i18nKey="publisher.search.notFound"
                components={[<Link key="0_notFound" color="violet.500" href={`/events-list`} />]}
              />
            </Text>
          }
        />
      )}
      {!data && (
        <Grid templateColumns="repeat(auto-fill, minmax(min(100%, 324px), 1fr))" gap="4">
          {Array.from({ length: DEFAULT_PAGE_SIZE }, (_, i) => (
            <EventCardFallback key={i} />
          ))}
        </Grid>
      )}
      {data && (
        <Box ref={eventsListRef}>
          <Grid templateColumns="repeat(auto-fill, minmax(min(100%, 324px), 1fr))" gap="4">
            {items.map((event) => (
              <NextLink key={event.id} href={getEventHrefFull(event)} passHref legacyBehavior>
                <UpcomingEventCard as="a" event={event} />
              </NextLink>
            ))}
            {isLoadingMore ? Array.from({ length: DEFAULT_PAGE_SIZE }, (_, i) => <EventCardFallback key={i} />) : null}
          </Grid>
          <InView
            onChange={(inView) => {
              if (inView && !error && !isReachingEnd) {
                setSize(size + 1);
              }
            }}
          >
            <span />
          </InView>
          {/* @ts-ignore*/}
          {!isEmpty && <BackToTop parentTopOffset={eventsListRef?.current?.offsetTop} />}
        </Box>
      )}
    </Box>
  );
};
