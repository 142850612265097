import { Box, Container, Flex } from '@chakra-ui/react';
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';

import { Search, SearchEmpty, SearchResults } from '@/components/ui/core/Search/Search';
import { Section } from '@/components/ui/core/Section/Section';
import { PublisherDescription } from '@/components/ui/features/publisher/PublisherDescription/PublisherDescription';
import { PublisherEventsGrid } from '@/components/ui/features/publisher/PublisherEventsGrid/PublisherEventsGrid';
import { PublisherHeader } from '@/components/ui/features/publisher/PublisherHeader/PublisherHeader';
import { PublisherSearchInput } from '@/components/ui/features/publisher/PublisherSearchInput/PublisherSearchInput';
import { PublisherSubNavigation } from '@/components/ui/features/publisher/PublisherSubNavigation/PublisherSubNavigation';
import { Layout } from '@/components/ui/shared/layouts/Layout/Layout';
import { NextQueryLinkProvider } from '@/components/ui/shared/utilities/NextQueryLink/NextQueryLink';
import { accessFrom } from '@/constants/custom-request-headers';
import { AccessFrom } from '@/enums/AccessFrom';
import { PublisherCategory } from '@/enums/PublisherCategory';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { queryThinConferenceBySlug, queryThinConferenceConfigurationRelationship } from '@/queries/thin-conferences';
import { generateStore } from '@/utils/generateStore';
import { Hydrate } from '@/utils/swr/Hydrate';
import { urlQueryStringValidate } from '@/utils/url-query-string';

interface IUrlQueryStringParams {
  tabId?: string;
}

const OrganizersPage: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
  accessFromLocation,
  fallback,
}) => {
  const { tabId = PublisherCategory.Description } = useQueryParams<IUrlQueryStringParams>({
    tabId: StringParam,
  });

  return (
    <Hydrate fallback={fallback}>
      <NextQueryLinkProvider value={{ accessFromLocation }}>
        <Layout accessFrom={accessFromLocation}>
          <Search minW="300px" maxW="100%">
            <Section variant="dimmed">
              <Container>
                <PublisherHeader />
                <Flex
                  justifyContent="space-between"
                  flexDirection={{ base: 'column-reverse', md: 'row' }}
                  mt={{ base: 12, md: 6 }}
                  mb={{ base: 6, md: 0 }}
                >
                  <PublisherSubNavigation />
                  <Box
                    minW="0"
                    maxW="100%"
                    display="flex"
                    flexDirection={{ base: 'column', md: 'row' }}
                    alignItems="center"
                  >
                    <PublisherSearchInput minW={{ base: '300px', md: '350px' }} maxW="500px" />
                  </Box>
                </Flex>
              </Container>
            </Section>
            <Section>
              <Container>
                {tabId === 'description' && (
                  <Box bg="white" pt={10}>
                    <SearchResults>
                      <PublisherEventsGrid />
                    </SearchResults>
                    <SearchEmpty>
                      <PublisherDescription />
                      <PublisherEventsGrid />
                    </SearchEmpty>
                  </Box>
                )}
                {tabId === 'events' && (
                  <Box bg="white" pt={10}>
                    <SearchResults>
                      <PublisherEventsGrid />
                    </SearchResults>
                    <SearchEmpty>
                      <PublisherEventsGrid />
                    </SearchEmpty>
                  </Box>
                )}
              </Container>
            </Section>
          </Search>
        </Layout>
      </NextQueryLinkProvider>
    </Hydrate>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { slug } = urlQueryStringValidate<{ slug: string }>(context.query, {
    slug: StringParam,
  });
  const store = generateStore({ context });

  try {
    const { data: publisherData } = await store.fetchResource(queryThinConferenceBySlug(slug));

    await store.fetchResourceRelationship(queryThinConferenceConfigurationRelationship(publisherData?.id));
  } catch (err) {
    return { notFound: true };
  }

  return {
    props: {
      fallback: store.fallback,
      accessFromLocation: context.req.headers[accessFrom] || AccessFrom.Core,
    },
  };
};

export default OrganizersPage;
