import { Icon, Button, forwardRef, Box } from '@chakra-ui/react';

import BackToTopIcon from '@/assets/icons/ic-back-to-top.svg';

import { useScrollBackTo } from '@/hooks/useScrollBackTo';

interface IBackToTopProps {
  parentTopOffset?: number;
  scrollingOffset?: number;
}

export const BackToTop = forwardRef<IBackToTopProps, 'div'>(function BackToTop(
  { parentTopOffset = 0, scrollingOffset = 100 },
  ref
) {
  const { onBackTo, shouldDisplayBackTo } = useScrollBackTo(parentTopOffset, scrollingOffset);

  if (!shouldDisplayBackTo) {
    return null;
  }

  return (
    <Box ref={ref} position="sticky" bottom="10px" maxW="max-content" left="100%">
      <Button variant="unstyled" color="black" onClick={onBackTo}>
        <Icon w="58px" h="58px" as={BackToTopIcon} />
      </Button>
    </Box>
  );
});
