import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

import { useLibLynx } from '@/hooks/useLibLynx';
import { ChangeMethod, useQueryParams } from '@/hooks/useQueryParams';
import { StringParam } from '@/utils/url-query-string';

export interface IUseSearchOptions {
  queryParamName?: string;
  method?: ChangeMethod;
  shallow?: boolean;
  scroll?: boolean;
  debounceWait?: number;
  minInputLength?: number;
}

const defaultOptions: IUseSearchOptions = {
  queryParamName: 'query',
  method: 'replace',
  shallow: true,
  debounceWait: 500,
  minInputLength: 3,
};

function validate(value, minInputLength) {
  return value?.length >= minInputLength ? value : null;
}

export function useSearch(options: IUseSearchOptions = {}) {
  const { queryParamName, method, shallow, debounceWait, minInputLength, scroll } = {
    ...defaultOptions,
    ...options,
  };
  const ref = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { [queryParamName]: query, change } = useQueryParams<Record<string, any>>({
    [queryParamName]: StringParam,
  });
  const libLynx = useLibLynx();

  useIsomorphicLayoutEffect(() => {
    const input = ref.current;

    const changeHandler = debounce(
      (event: Event) => {
        const target = event.target as HTMLInputElement;
        const value = validate(target.value, minInputLength);

        libLynx.send('trackSearch');
        change({ [queryParamName]: value ? value : null }, { shallow, method, scroll });
      },
      debounceWait,
      { leading: false }
    );

    if (input) {
      input.addEventListener('input', changeHandler);
    }

    return () => {
      if (input) {
        input.removeEventListener('input', changeHandler);
      }
    };
  }, [router, queryParamName, change, method, shallow, debounceWait, minInputLength, libLynx.send, scroll]);

  const getSearchInputProps = () => ({
    ref,
    defaultValue: query,
  });

  return {
    query,
    getSearchInputProps,
  };
}
