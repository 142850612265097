import { HStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TabLink } from '@/components/ui/shared/tab-link/TabLink';
import { NextQueryLink } from '@/components/ui/shared/utilities/NextQueryLink/NextQueryLink';
import { PublisherCategory } from '@/enums/PublisherCategory';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';

export interface IUrlQueryStringParams {
  tabId?: string;
  query?: string;
}

export const PublisherSubNavigation: FC = () => {
  const { tabId: selectedMenuTabId = PublisherCategory.Description, query } = useQueryParams<IUrlQueryStringParams>({
    eventId: StringParam,
    tabId: StringParam,
  });
  const { t } = useTranslation();

  return (
    <HStack alignItems="baseline" overflowX="auto" spacing={8}>
      <NextQueryLink key="description" query={{ tabId: 'description' }} shallow scroll={false}>
        <TabLink
          isSelected={selectedMenuTabId === 'description' && !query}
          isDisabled={Boolean(query)}
          _hover={{
            textDecoration: 'none',
          }}
        >
          {t('common.description')}
        </TabLink>
      </NextQueryLink>
      <NextQueryLink key="events" query={{ tabId: 'events' }} shallow scroll={false}>
        <TabLink
          isSelected={selectedMenuTabId === 'events' && !query}
          isDisabled={Boolean(query)}
          _hover={{
            textDecoration: 'none',
          }}
        >
          {t('common.event_plural')}
        </TabLink>
      </NextQueryLink>
    </HStack>
  );
};
