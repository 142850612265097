import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { createContext, FC, ReactNode, useContext } from 'react';

import { AccessFrom } from '@/enums/AccessFrom';

interface INextQueryLink extends Omit<LinkProps, 'href' | 'as'> {
  query: ParsedUrlQueryInput;
  children: ReactNode;
}

const NextQueryLinkContext = createContext({ accessFromLocation: AccessFrom.Core });

export const NextQueryLinkProvider = NextQueryLinkContext.Provider;
export const useNextQueryLink = () => useContext(NextQueryLinkContext);

export const NextQueryLink: FC<INextQueryLink> = ({ query, children, ...rest }) => {
  const { accessFromLocation } = useNextQueryLink();
  const router = useRouter();

  const pathname = accessFromLocation === AccessFrom.Publisher ? '/' : router.pathname;

  return (
    <NextLink
      href={{
        pathname,
        query: omitBy({ ...router.query, ...query }, isNil),
      }}
      {...rest}
      legacyBehavior
    >
      {children}
    </NextLink>
  );
};
