import { Card, HTMLChakraProps } from '@chakra-ui/react';
import { FC } from 'react';

import { Markdown } from '@/components/Markdown';

export interface IDescriptionCardProps extends HTMLChakraProps<'div'> {
  description: string;
}

export const DescriptionCard: FC<IDescriptionCardProps> = ({ description, ...rest }) => {
  return (
    <Card variant="elevated" p={4} {...rest}>
      <Markdown>{description}</Markdown>
    </Card>
  );
};
