import { Link, LinkProps, Skeleton, forwardRef } from '@chakra-ui/react';
import { ariaAttr } from '@chakra-ui/utils';

interface ITabLinkProps extends LinkProps {
  isSelected?: boolean;
  isDisabled?: boolean;
}

export const TabLink = forwardRef<ITabLinkProps, 'a'>(({ isSelected, isDisabled, children, ...rest }, ref) => {
  return (
    <Link
      p="14px 0 10px"
      bg="none"
      ref={ref}
      border="none"
      cursor="pointer"
      outline="none"
      fontSize="xl"
      fontWeight="bold"
      lineHeight={7}
      whiteSpace="nowrap"
      appearance="none"
      borderBottom="4px solid transparent"
      textTransform="lowercase"
      aria-selected={ariaAttr(isSelected)}
      _selected={{
        color: 'violet.500',
        borderColor: 'violet.500',
      }}
      aria-disabled={ariaAttr(isDisabled)}
      _disabled={{
        color: 'gray.200',
        pointerEvents: 'none',
      }}
      {...rest}
    >
      {children}
    </Link>
  );
});
