import {
  AspectRatio,
  Box,
  Flex,
  forwardRef,
  Heading,
  HTMLChakraProps,
  Image,
  Skeleton,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ThinEvent } from '@/resources/ThinEvent';

const EventDurationWithTimezone = dynamic(
  () =>
    import('@/components/ui/shared/event/EventDurationWithTimezone/EventDurationWithTimezone').then(
      (mod) => mod.EventDurationWithTimezone
    ),
  {
    ssr: false,
  }
);

export interface IEventCardProps extends HTMLChakraProps<'div'> {
  event: ThinEvent;
  footer: ReactNode;
}

export const EventCard = forwardRef<IEventCardProps, 'div'>(function EventCard(props, ref) {
  const { event, footer, ...rest } = props;

  return (
    <Flex flexDir="column" _hover={{ textDecor: 'none' }} ref={ref} {...rest} border={'1px solid #CACCCC'}>
      <AspectRatio maxW="full" ratio={3 / 2}>
        <Image src={event.mediumHeaderImageUrl} alt="" fallback={<Skeleton size="full" />} />
      </AspectRatio>

      <Flex
        pos="relative"
        flexDir="column"
        flexGrow={1}
        p={[6, null, 8]}
        pt={[12, null, 16]}
        backgroundColor="white"
        justifyContent="space-between"
      >
        <AspectRatio
          pos="absolute"
          top="0"
          left={[6, null, 8]}
          transform="translateY(-50%)"
          w={[16, null, '84px']}
          ratio={1}
        >
          <Image
            src={event.smallLogoUrl}
            alt="logo"
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
            bg="white"
            fallback={<Box bg="gray.200" w="full" borderRadius="md" />}
          />
        </AspectRatio>
        <Heading as="h3" mb={[3, null, 4]} fontSize={['2xl', null, '3xl']} lineHeight="shorter" noOfLines={2}>
          {event.name}
        </Heading>
        {event.shortDescription && <Text noOfLines={4}>{event.shortDescription}</Text>}

        {footer}
      </Flex>
    </Flex>
  );
});

export type UpcomingEventCardProps = Omit<IEventCardProps, 'footer'>;

export const UpcomingEventCard = forwardRef<Omit<UpcomingEventCardProps, 'footer'>, typeof EventCard>(
  function UpcomingEventCard(props, ref) {
    const { event, ...rest } = props;

    return (
      <EventCard
        ref={ref}
        event={event}
        footer={
          <Box mt="14">
            <EventDurationWithTimezone event={event} />
          </Box>
        }
        {...rest}
      />
    );
  }
);

export type PastEvenCardProps = Omit<IEventCardProps, 'footer'>;

export const PastEventCard = forwardRef<PastEvenCardProps, typeof EventCard>(function PastEventCard(props, ref) {
  const { event, ...rest } = props;
  const { t } = useTranslation();
  const lectureCount = parseInt(event.lecturesCount, 10);
  const speakerCount = parseInt(event.speakersCount, 10);

  return (
    <EventCard
      ref={ref}
      event={event}
      footer={
        <Flex flexWrap="wrap" mt="14" mx="-2">
          <Text as="p" flex="1" px="2" fontSize="md" lineHeight="base">
            <Heading as="span" mr="2" fontSize={['xl', null, '2xl']} lineHeight="shorter">
              {lectureCount}
            </Heading>
            {t('common.lecture', { count: lectureCount })}
          </Text>
          <Text as="p" flex="1" px="2" fontSize="md" lineHeight="base">
            <Heading as="span" mr="2" fontSize={['xl', null, '2xl']} lineHeight="shorter">
              {speakerCount}
            </Heading>
            {t('common.speaker', { count: speakerCount })}
          </Text>
        </Flex>
      }
      {...rest}
    />
  );
});

export type EventCardFallbackProps = HTMLChakraProps<'div'>;

export const EventCardFallback = forwardRef<EventCardFallbackProps, 'div'>(function EventCardFallback(props, ref) {
  return (
    <Flex ref={ref} flexDir="column" {...props}>
      <AspectRatio maxW="full" ratio={3 / 2}>
        <Skeleton />
      </AspectRatio>

      <Flex pos="relative" flexDir="column" flexGrow={1} p={[6, null, 8]} pt={[12, null, 16]} backgroundColor="white">
        <AspectRatio
          pos="absolute"
          top="0"
          left={[6, null, 8]}
          transform="translateY(-50%)"
          w={[16, null, 28]}
          ratio={1}
        >
          <Skeleton borderRadius="full" />
        </AspectRatio>
        <Skeleton h={['1.875rem', null, 9]} mb={[3, null, 4]} />
        <SkeletonText noOfLines={2} skeletonHeight="4" spacing="2" py="1" mb="auto" />
        <Skeleton h={[6, null, '1.875rem']} mt="14" />
      </Flex>
    </Flex>
  );
});
