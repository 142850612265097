import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  ThemingProps,
  useStyles,
  useMultiStyleConfig,
  StylesProvider,
} from '@chakra-ui/react';
import { __DEV__, cx } from '@chakra-ui/utils';

export type IMediaImageProps = HTMLChakraProps<'img'>;
export type IMediaBodyProps = HTMLChakraProps<'div'>;
export interface IMediaProps extends HTMLChakraProps<'div'>, ThemingProps<'Media'> {}

export const Media = forwardRef<IMediaProps, 'div'>(function Media(props, ref) {
  const { className, children, ...rest } = props;
  const styles = useMultiStyleConfig('Media', props);
  const chakraClassName = cx('chakra-media', className);

  return (
    <chakra.div ref={ref} className={chakraClassName} __css={styles.root} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </chakra.div>
  );
});

if (__DEV__) {
  Media.displayName = 'Media';
}

export const MediaImage = forwardRef<IMediaImageProps, 'img'>(function MediaImage(props, ref) {
  const { className, ...rest } = props;
  const styles = useStyles();
  const chakraClassName = cx('chakra-media_image', className);

  return <chakra.img ref={ref} className={chakraClassName} __css={styles.image} {...rest} />;
});

if (__DEV__) {
  MediaImage.displayName = 'MediaImage';
}

export const MediaBody = forwardRef<IMediaBodyProps, 'div'>(function MediaBody(props, ref) {
  const { children, className, ...rest } = props;
  const chakraClassName = cx('chakra-media_body', className);
  const styles = useStyles();

  return (
    <chakra.div ref={ref} className={chakraClassName} __css={styles.body} {...rest}>
      {children}
    </chakra.div>
  );
});

if (__DEV__) {
  MediaBody.displayName = 'MediaBody';
}
